/** @format */

import React, { useState, useEffect } from "react";
import { Card, Typography, CardContent, Button, Link } from "@mui/material";
import classnames from "classnames";

import ValidateDemoGraphic from "../../validation/validateDemoGraphic";
import TextField from "../common/TextField";
import useGlobalContext from "../../hooks/useGlobalContext";
import CardField from "../common/CardField";
import RadioFollowUp from "../common/RadioFollowUp";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const DemographicInformation = ({ currentSection, setCurrentSection }) => {
  const { demographicInformation, setDemographicInformation } =
    useGlobalContext();

  const [errors, setErrors] = useState({});

  const [demographicInformationValue, setDemographicInformationValue] =
    useState({
      checkedEthnicityItems: [],
      radioSexItem: "",
      radioPreferPronounItem: "",
      maritalStatusItems: "",
      fullName: "",
      birth: moment(),
      email: "",
      phoneNumber: "",
      pregnant: null,
      firstName: "",
      lastName: "",
    });

  const checkedEthnicityOptions = [
    {
      label: "American Indian or Alaska Native",
      value: "American Indian or Alaska native",
      name: "American Indian or Alaska Native",
    },
    {
      label: "Southeast Asian",
      value: "Southeast Asian",
      name: "Southeast Asian",
    },
    {
      label: "Black or African American",
      value: "Black or African American",
      name: "Black or African American",
    },
    {
      label: "Native Hawaiian or Other Pacific Islander",
      value: "Native Hawaiian or Other Pacific Islander",
      name: "Native Hawaiian or Other Pacific Islander",
    },
    {
      label: "Caucasian",
      value: "Caucasian",
      name: "Caucasian",
    },
    {
      label: "Hispanic or Latino or Spanish Origin",
      value: "Hispanic or Latino or Spanish Origin",
      name: "Hispanic or Latino or Spanish Origin",
    },
    { label: "East Indian", value: "East Indian", name: "East Indian" },
    {
      label: "Prefer not to answer",
      value: "Prefer not to answer",
      name: "Prefer not to answer",
    },
    { label: "Other", value: "Other", name: "Other" },
  ];

  const radioFemalePregnantOptions = [
    { label: "Yes", value: "Yes", name: "radioFemalePregnantOptionsYes" },
    { label: "No", value: "No", name: "radioFemalePregnantOptionsNo" },
    {
      label: "I don't know/I'm not sure",
      value: "I don't know/I'm not sure",
      name: "I don't know/I'm not sure",
    },
  ];

  const radioPreferPronounOptions = [
    { label: "He/him/his", value: "he/him/his", name: "PreferPronounHim" },
    { label: "She/her/hers", value: "she/her/hers", name: "PreferPronounHer" },
    {
      label: "They/them/theirs",
      value: "they/them/theirs",
      name: "PreferPronounThem",
    },
    {
      label: "Other",
      value: "other",
      name: "PreferPronounOther",
    },
    {
      label: "Prefer not to say",
      value: "prefer not to say",
      name: "PreferPronounNotSay",
    },
  ];

  const maritalStatusOptions = [
    { label: "Single", value: "single", name: "maritalStatusSingle" },
    { label: "Married", value: "married", name: "maritalStatusMarried" },
    { label: "Divorced", value: "divorced", name: "maritalStatusDivorced" },
    { label: "Separated", value: "separated", name: "maritalStatusSeparated" },
    { label: "Widowed", value: "widowed", name: "maritalStatusWidowed" },
    {
      label: "Living together",
      value: "living together",
      name: "maritalStatusLivingTogether",
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setDemographicInformationValue(demographicInformation);
  }, [demographicInformation]);

  const handleCheckboxEthnicityChange = (event) => {
    const itemName = event.target.value;
    const isChecked = event.target.checked;

    let newCheckedItems = [
      ...demographicInformationValue?.checkedEthnicityItems,
    ];

    if (isChecked) {
      newCheckedItems.push(itemName);
    } else {
      newCheckedItems = newCheckedItems.filter((item) => item !== itemName);
    }

    setDemographicInformationValue({
      ...demographicInformationValue,
      checkedEthnicityItems: newCheckedItems,
    });
  };

  const handleRadioSexChange = (event) => {
    setDemographicInformationValue({
      ...demographicInformationValue,
      radioSexItem: event.target.value,
      pregnant: null,
    });
  };

  const handleRadioPreferPronounChange = (event) => {
    setDemographicInformationValue({
      ...demographicInformationValue,
      radioPreferPronounItem: event.target.value,
    });
  };

  const handleRadioMaritalStatusChange = (event) => {
    setDemographicInformationValue({
      ...demographicInformationValue,
      maritalStatusItems: event.target.value,
    });
  };

  const handleChange = (event) => {
    setDemographicInformationValue({
      ...demographicInformationValue,
      [event.target.name]: event.target.value,
    });
  };

  const handlePregnantChange = (event) => {
    setDemographicInformationValue({
      ...demographicInformationValue,
      pregnant: event.target.value,
    });
  };

  const handleDateChange = (date) => {
    const dateModified = moment(date).format("YYYY-MM-DD");

    setDemographicInformationValue({
      ...demographicInformationValue,
      birth: dateModified,
    });
  };

  const handlePhoneChange = (event) => {
    let input = event.target.value;
    const cleaned = input.replace(/\D/g, "");
    const truncated = cleaned.slice(0, 10);
    const match = truncated.match(/^(\d{3})(\d{3})(\d{4})$/);
    let num = truncated;
    if (match) {
      num = `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    setDemographicInformationValue({
      ...demographicInformationValue,
      phoneNumber: num,
    });
  };

  const handleNextClick = () => {
    // setDemographicInformation(demographicInformationValue);
    // setCurrentSection(currentSection + 1);

    const { isValid, errors } = ValidateDemoGraphic(demographicInformationValue);
    setErrors(errors);
    console.log("Demographic Information validation errors", errors);

    if (isValid) {
      setDemographicInformation(demographicInformationValue);
      setCurrentSection(currentSection + 1);
    } else {
      toast.error("Please fill in all fields correctly!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="mt-4">
      <div className="w-[65%] mx-auto p-3 text-xl">
        <Typography sx={{ mt: 3 }}>
          Please note, your responses submitted on this questionnaire are not reviewed until the time of your scheduled appointment with the clinician. If you are at imminent risk of harm to yourself or others, please contact the resources provided below.
        </Typography>
        <Typography sx={{ mt: 3 }}>
          For immediate assistance, dial 911 or go to your local emergency room. A 24-hour suicide prevention crisis line can be reached by calling 1-800-SUICIDE, 988, or online at&nbsp;
          <Link
            href="https://988lifeline.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://988lifeline.org
          </Link>
        </Typography>
      </div>
      <div className="mt-4">
        <p className="bg-green-400 w-[65%] mx-auto p-3 text-xl text-white rounded-lg mt-5 shadow-lg">
          Demographic Information, Part I
        </p>

        <form>
          <TextField
            title="1. What is your first name?"
            type="text"
            name="firstName"
            value={demographicInformationValue?.firstName}
            placeholder="Your answer..."
            onChange={handleChange}
            error={errors.firstName}
          />

          <TextField
            title="2. What is your last name?"
            type="text"
            name="lastName"
            value={demographicInformationValue?.lastName}
            placeholder="Your answer..."
            onChange={handleChange}
            error={errors.lastName}
          />

          <div className="w-[68%] mx-auto mt-3">
            <div className="w-[95%] mx-auto p-3 shadow-lg">
              <p className="text-left text-[20px] mt-2">
                3. What is your date of birth? (Please enter the date in format
                MM-DD-YYYY)
              </p>
              <p className="h-0.5 bg-gray-400 w-100 mt-2 mb-4"></p>
              <DatePicker
                name="birth"
                value={moment(demographicInformationValue.birth)}
                onChange={(newDate) => handleDateChange(newDate)}
              />
              {errors.birth && (
                <div className="text-red-500 text-left text-[12px] mt-2 block ml-11">
                  {errors.birth}
                </div>
              )}
            </div>
          </div>

          <CardField
            title="4. Please select any of the following that represent your race or ethnicity. You may select more than one."
            type="checkbox"
            options={checkedEthnicityOptions}
            onChange={handleCheckboxEthnicityChange}
            checked={demographicInformationValue.checkedEthnicityItems}
            errors={errors.checkedEthnicityItems}
          />

          <Card sx={{ width: "65%", margin: "auto", marginTop: 3 }}>
            <CardContent>
              <Typography sx={{ fontSize: 20, textAlign: "left" }}>
                5. What sex was assigned to you at birth?
              </Typography>
              <p className="h-0.5 bg-gray-400 w-100 mt-2"></p>
              <div className="mt-5">
                <div
                  className={classnames("mt-5 p-2", {
                    "border-red-500 border": errors.radioSexItem,
                  })}
                >
                  <div className="text-left">
                    <label>
                      <input
                        type="radio"
                        value="male"
                        className="mr-2"
                        checked={
                          demographicInformationValue?.radioSexItem === "male"
                        }
                        onChange={handleRadioSexChange}
                      />
                      Male
                    </label>
                  </div>
                  <div className="text-left">
                    <label>
                      <input
                        type="radio"
                        value="female"
                        className="mr-2"
                        checked={
                          demographicInformationValue?.radioSexItem === "female"
                        }
                        onChange={handleRadioSexChange}
                      />
                      Female
                    </label>
                    {demographicInformationValue?.radioSexItem === "female" ? (
                      <RadioFollowUp
                        title="Are you pregnant, planning on getting pregnant, or
                          breastfeeding?"
                        options={radioFemalePregnantOptions}
                        onChange={handlePregnantChange}
                        error={errors.pregnant}
                        checked={demographicInformationValue?.pregnant}
                      />
                    ) : null}
                  </div>

                  <div className="text-left">
                    <label>
                      <input
                        type="radio"
                        value="prefer not to say"
                        className="mr-2"
                        checked={
                          demographicInformationValue?.radioSexItem ===
                          "prefer not to say"
                        }
                        onChange={handleRadioSexChange}
                      />
                      Prefer not to say
                    </label>
                  </div>
                </div>
                {errors.radioSexItem && (
                  <div className="text-red-500 text-left text-[12px] mt-2">
                    {errors.radioSexItem}
                  </div>
                )}
              </div>
            </CardContent>
          </Card>

          <CardField
            title="6. What pronoun do you currently prefer?"
            type="radio"
            options={radioPreferPronounOptions}
            onChange={handleRadioPreferPronounChange}
            checked={demographicInformationValue?.radioPreferPronounItem}
            errors={errors.radioPreferPronounItem}
          />

          <CardField
            title="7. What is your marital status?"
            type="radio"
            options={maritalStatusOptions}
            onChange={handleRadioMaritalStatusChange}
            checked={demographicInformationValue?.maritalStatusItems}
            errors={errors.maritalStatusItems}
          />

          <TextField
            type="text"
            title="8. What is your email?"
            name="email"
            value={demographicInformationValue?.email}
            placeholder="Your answer..."
            onChange={handleChange}
            error={errors.email}
          />

          <TextField
            title="9. What is your phone number? (Please enter phone number in format 111 111-1111)"
            type="text"
            name="phoneNumber"
            value={demographicInformationValue?.phoneNumber}
            placeholder="111 111-1111"
            onChange={handlePhoneChange}
            error={errors.phoneNumber}
          />

          <div className="mx-auto w-[65%] flex justify-between mt-3">
            <Button variant="contained" type="button" onClick={handleNextClick}>
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DemographicInformation;
